import { combineReducers, createStore } from '@reduxjs/toolkit';
import {
	companyReducer,
	dashboardReducer,
	datasetIDReducer,
	errorReducer,
	getDataStatusReducer,
	loadReducer,
	loggedReducer,
	notificationReducer,
	selectedIndicatorsForAllReducer,
	setTemporaryAssignedUsersEmailReducer,
	studyIDReducer,
	tooltipStateReducer,
	userAssignmentsReducer,
	userNotificationReducer,
	versionReducer
} from './reducers/currentDatas';
import { SettingsDataEntity } from '../domain/entities/SettingsEntity';
import { MaturityDataEntity } from '../domain/entities/MaturityEntity';
import { InventoryDataEntity } from '../domain/entities/InventoryEntity';
import { ValidationDataEntity } from '../domain/entities/ValidationEntity';
import { StudiesEntity } from '../domain/entities/StudiesEntity';
import { StaticPagesEntity } from '../domain/entities/StaticPagesEntity';
import { LambdaInventoryEntity } from '../domain/entities/LambdaInventoryEntity';
import { IModal } from '../domain/interfaces/IModal';
import { IndicatorEntity } from '../domain/entities/IndicatorEntity';
import { LifeCycleStepEntity } from '../domain/entities/LifeCycleStepEntity';
import { SampleEntity } from '../domain/entities/SampleEntity';
import { UserDashboardEntity } from '../domain/entities/UserDashboardEntity';
import { DashboardEntity } from '../domain/entities/DashboardEntity';
import { BlockEntity } from '../domain/entities/BlockEntity';
import { DatasetsEntity } from '../domain/entities/DatasetsEntity';
import { ManageEquipmentEntity } from '../domain/entities/ManageEquipmentEntity';
import { LifeCycleAssessmentEntity } from '../domain/entities/LifeCycleAssessmentEntity';
import { ResultsEntity } from '../domain/entities/ResultsEntity';
import { ResultLaunchersEntity } from '../domain/entities/ResultLaunchersEntity';
import { CmdbEntity } from '../domain/entities/CmdbEntity';

export const reducers = combineReducers({
	load: loadReducer,
	version: versionReducer,
	logged: loggedReducer,
	company: companyReducer,
	userAssignments: userAssignmentsReducer,
	studyID: studyIDReducer,
	datasetID: datasetIDReducer,
	dataStudiesStatus: getDataStatusReducer<StudiesEntity>('studies'),
	dataDatasetsStatus: getDataStatusReducer<DatasetsEntity>('datasets'),
	dataSettingsStatus: getDataStatusReducer<SettingsDataEntity>('settings'),
	dataMaturityStatus: getDataStatusReducer<MaturityDataEntity>('maturity'),
	dataInventoryStatus: getDataStatusReducer<InventoryDataEntity>('inventory'),
	dataEquipmentStatus: getDataStatusReducer<ManageEquipmentEntity>('equipment'),
	dataResultsStatus: getDataStatusReducer<ResultsEntity>('results'),
	dataResultLaunchersStatus: getDataStatusReducer<ResultLaunchersEntity>('resultLaunchers'),
	dataIndicatorsStatus: getDataStatusReducer<IndicatorEntity>('indicators'),
	dataBlocksStatus: getDataStatusReducer<BlockEntity>('blocks'),
	dataModalStatus: getDataStatusReducer<IModal>('modal'),
	dataLifeCycleStatus: getDataStatusReducer<LifeCycleStepEntity>('lifeCycleSteps'),
	dataValidationStatus: getDataStatusReducer<ValidationDataEntity>('validation'),
	dataStaticPageStatus: getDataStatusReducer<StaticPagesEntity>('staticPage'),
	dataLambdaInventoryStatus: getDataStatusReducer<LambdaInventoryEntity>('lambdaInventory'),
	dataSampleListStatus: getDataStatusReducer<SampleEntity>('sampleList'),
	dataUserDashboards: getDataStatusReducer<UserDashboardEntity>('userDashboards'),
	dataDashboards: getDataStatusReducer<DashboardEntity>('dashboards'),
	dataLca: getDataStatusReducer<LifeCycleAssessmentEntity>('lca'),
	dataCmdb: getDataStatusReducer<CmdbEntity>('cmdb'),
	dashboard: dashboardReducer,
	temporaryAssignedUsersEmail: setTemporaryAssignedUsersEmailReducer,
	notifications: notificationReducer,
	error: errorReducer,
	tooltipState: tooltipStateReducer,
	userNotifications: userNotificationReducer,
	selectedIndicatorsForAll: selectedIndicatorsForAllReducer
});

export type State = ReturnType<typeof reducers>

export const store = createStore(reducers);
