import React, { useCallback, useEffect, useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { useIndicators } from '../../hooks/useIndicators';
import { closeGlobalModal } from '../GlobalModal';
import { MultiChoiceDropdown, MultiChoiceDropdownProps } from '../../selector/MultiChoiceDropdown/MultiChoiceDropdown';
import { CheckBoxText } from '../../utils/CheckBoxText/CheckBoxText';
import './FilterIndicatorModal.scss';
import { useDatasets } from '../../hooks/useDatasets';
import { useParams } from 'react-router-dom';
import { changeSelectedIndicatorsForAll } from '../../../store/dispatchers';

// TODO: This component need to be update for multiple datasets and sample results
export const useFilterIndicatorModal = (openModal: boolean, setOpenModal: (bool: boolean) => void) => {
	const { dataset: datasetId } = useParams<{ dataset: string}>();
	const { entity: indicatorEntity, indicators, selectedIndicators } = useIndicators();
	const { entity: datasetEntity, currentDataset } = useDatasets(datasetId ? [datasetId] : []);

	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	// List of indicators used for computation -> indicators from dataset inventoryExtra
	const [indicatorsList, setIndicatorsList] = useState<string[]>([]);

	// Tmp list of selectedIndicators in the current modal
	const [newSelectedIndicators, setNewSelectedIndicators] = useState<string[] | null>(null);
	const [selectAll, setSelectAll] = useState<boolean>(false);

	const excludedIndicators = ['WEEE', 'MIPS'];
	const list: MultiChoiceDropdownProps['list'] = [];

	const selectAllIndicators = useCallback(() => {
		const allIndicatorIds = indicators.filter(i => indicatorsList.includes(i.id) || !currentDataset).map(indicator => indicator.id);
		setNewSelectedIndicators(allIndicatorIds);
	}, [indicatorsList]);

	const deselectAllIndicators = useCallback(() => {
		setNewSelectedIndicators([]);
	}, []);

	const updateSelectedIndicators = () => {
		if (!currentDataset || !currentDataset.id) {
			setOpenModal(false);
			closeGlobalModal();
			changeSelectedIndicatorsForAll(newSelectedIndicators ?? []);
			return;
		}
		setLoading(true);
		datasetEntity?.mutateFilterIndicators({
			datasetId: currentDataset.id,
			indicatorIds: newSelectedIndicators ?? [] }
		).then(() => {
			setOpenModal(false);
			closeGlobalModal();
		}).catch((e) => {
			setError(e.message);
		}).finally(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		if (currentDataset) {
			datasetEntity?.queryInventoryExtra({ datasetId: currentDataset.id }).then(data => setIndicatorsList(data.indicators));

			// Set the current selected indicators to the currentDataset.selectedIndicators when dataset is correctly loaded
			setNewSelectedIndicators(currentDataset.selectedIndicators ?? []);
		}
	}, [currentDataset]);
	useEffect(() => {
		if (!currentDataset) {
			setIndicatorsList(indicators.map(i => i.id));
			setNewSelectedIndicators(selectedIndicators?.map(i => i.id) ?? []);
		}
	}, [currentDataset, indicators]);

	useEffect(() => {
		if (newSelectedIndicators?.length === indicatorsList.length) setSelectAll(true);
		else setSelectAll(false);
	}, [indicatorsList, newSelectedIndicators]);

	if (!openModal || !indicatorEntity) return;

	// list creation based on all indicators and indicators from inventoryExtra of the dataset
	indicators.filter(i => indicatorsList.includes(i.id) || !currentDataset).forEach((indicator) => {
		if (excludedIndicators.includes(indicator.shortName)) return;
		list.push({
			id: indicator.id,
			dropChildren: [],
			selectable: true,
			element: `${indicator.shortName} - ${indicator.name}`,
		});
	});

	const body = <div className='filter_indicator_modal_body'>
		<h2>{translate('filterIndicatorModal.filter')}</h2>
		<CheckBoxText
			className='select_all'
			text={translate('selectAll') as string}
			checked={selectAll}
			onChange={(checked) =>{
				setSelectAll(checked);
				checked ? selectAllIndicators() : deselectAllIndicators();
			}}
			textIsRight={true}
		/>
		<MultiChoiceDropdown
			list={list}
			values={newSelectedIndicators ?? selectedIndicators?.map((indicator) => indicator.id) ?? []}
			onChange={(ids: string[]) => setNewSelectedIndicators([...ids])}
		/>
	</div>;

	return {
		action: updateSelectedIndicators,
		visible: true,
		body: body,
		error: !!error,
		errorMessage: error,
		setError: setError,
		load: loading,
		header: translate('filterIndicatorModal.title') as string,
		onClose: () => {
			setNewSelectedIndicators(null);
			setOpenModal(false);
		}
	};
};