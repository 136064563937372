import React, { useEffect } from 'react';
import './contentHeader.scss';
import DatasetSelector from '../datasetSelector/datasetSelector';
import TitleHeader from '../titleHeader/titleHeader';
import { SideBarContentItemList } from '../../template/sidebar/sidebar';
import { translate } from '../../../infrastructure/translations/translate';
import { useLocation } from 'react-router-dom';
import { createUpdateModal } from '../../modal/UpdateModal/UpdateModal';
import { useModal } from '../../modal/useModal';
import { useUser } from '../../hooks/useUser';

const ContentHeader = ({ content }: SideBarContentItemList): JSX.Element => {
	const { entity: userEntity } = useUser();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const update = query.get('update');

	const [openUpdateModal, setOpenUpdateModal] = React.useState<boolean>(false);
	const [updateContent, setUpdateContent] = React.useState<string>('');
	const location = useLocation();
	const inHomeView = location.pathname === '/';
	inHomeView && useModal(openUpdateModal, createUpdateModal(openUpdateModal, setOpenUpdateModal, updateContent));

	useEffect(() => {
		setOpenUpdateModal(update === 'true');
	}, [update]);

	useEffect(() => {
		if (inHomeView) {
			userEntity?.queryUpdate().then((res) => {
				if (res && res.update)
					setUpdateContent(res.update.content);
			});
		}
	}, []);

	return (
		<header className="head">
			<TitleHeader content={content}/>
			{inHomeView && updateContent.length > 0 && <button className="update_button" onClick={() => setOpenUpdateModal(true)}>
				<i className="fas fa-sparkles"/>
				<span className="update_button_text">{translate('whatsNew')}</span>
			</button>}
			<DatasetSelector />
		</header>
	);
};

export default ContentHeader;
