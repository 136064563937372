import React, { useState } from 'react';
import Modal from '../Modal';
import { translate } from '../../../infrastructure/translations/translate';
import Spinner from '../../utils/Spinner/Spinner';
import { useResultLaunchers } from '../../hooks/useResultLaunchers';

type ResultsModalType = 'none' | 'load' | 'done' | 'error' | 'archive'
/**
 * Return Modal for archive results
 * @param {string} rlId - Result Launcher Id
 * @param {() => void} onClose
 *
 * @author Maximilien Valenzano
 */
export const ResultsModal = ({
	rlId,
	onClose
}: { rlId: string, onClose: () => void }): JSX.Element => {
	const { entity: rlEntity } = useResultLaunchers();
	const [typeModal, setTypeModal] = useState<ResultsModalType>('none');
	const [error, setError] = useState<string | null>(null);
	const select = (value: ResultsModalType) => {
		setTypeModal(value);
	};
	const modify = () => {
		if (['archive'].includes(typeModal)) {
			setTypeModal('load');
			if (typeModal === 'archive') rlEntity?.mutateArchiveResults({ rlId }).then(success => {
				setTypeModal(success ? 'done' : 'error');
			}).catch(error => {
				setTypeModal('error');
				setError(error.message);
			});
		}
	};
	const handleClose = () => {
		if (typeModal !== 'load') onClose();
	};
	return (
		<Modal onClose={() => handleClose()}>
			<div className={'modal_content'}>
				<div className={'modal_header'}>
					<h4>{translate('validation.archiveResults')}</h4>
				</div>
				<div className={'modal_body'}>
					{typeModal === 'none' && <>
						<button type={'button'} className={'button_primary'}
							onClick={() => select('archive')}>{translate('validation.archiveResults')}</button>
					</>}
					{typeModal === 'archive' && <p>{translate('validation.archiveResultsDescription')}</p>}
					{typeModal === 'load' && <div className={'text-center'}><Spinner/></div>}
					{typeModal === 'done' && <p>{translate('validation.archiveResultsDone')}</p>}
					{typeModal === 'error' && <>
						<p>{translate('validation.archiveResultsError')}</p>
						<p>{error ?? translate('validation.archiveResultsNoInfo')}</p>
					</>}
				</div>
				<div className={'modal_footer'}>
					{typeModal !== 'load' && <button type={'button'} onClick={() => handleClose()}
						className={'button_primary'}>{translate(typeModal === 'done' ? 'close' : 'cancel')}</button>}
					{typeModal === 'archive' && <button type={'button'} className={'button_danger'}
						onClick={() => modify()}>{translate('yes')}</button>}
				</div>
			</div>
		</Modal>);
};