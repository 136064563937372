import { gql } from '@apollo/client';

export const QUERY_MODAL_EQUIPMENT_TYPES_INFOS = gql`
	query ModalEquipmentTypesInfos (
		$datasetId: String!
	) {
		modalEquipmentTypesInfos (datasetId: $datasetId) {
			id
			name
			factorSet (datasetId: $datasetId) {
				equipmentTypeId,
				equipmentTypeName,
				equipmentTypeSource,
				equipmentTypeShortNameAndSpecification
				dataReference {
					reference,
					isHash
				}
				version,
				updatedAt
			}
		}
	}
`;