import { gql } from '@apollo/client';

export const MUTATE_MATURITY_LINE = gql`
    mutation SaveGoodPracticeLine(
      $id: String!,
      $values: GoodPracticeInput!
    ) {
        mutateSaveGoodPracticeLine (
            id: $id,
            values: $values
        ) {
            status,
            goodPractice {
                id,
                name,
                help,
                kpi,
                kpiValue,
                difficulty,
                priority,
                grade,
                comment,
                originDatabaseId,
                flag,
            }
            dataset {
            	id
                status {
                	maturity
                }
            }
        }
    }
`;
