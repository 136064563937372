import { StudyDataset } from '../data/entries/StudyDataset';
import { Entity } from '../core/Entity';
import {
	ApolloClientMutateCheckComputeStatus, ApolloClientMutateCopyDataset,
	ApolloClientMutateCreateDatasetFromTemplate,
	ApolloClientMutateDatasetManagement,
	ApolloClientMutateEditInventoryExtra,
	ApolloClientMutateFilterIndicators,
	ApolloClientMutateLockDataset,
	ApolloClientMutateRequestCalculation,
	ApolloClientQueryDatasets,
	ApolloClientQueryInventoryExtra,
	ApolloClientQueryIsQualityEmpty
} from '../../infrastructure/ApolloClass/DatasetClass';
import {
	MUTATE_CREATE_DATASET_FROM_TEMPLATE
} from '../../infrastructure/ApolloClient/requests/MUTATE_CREATE_DATASET_FROM_TEMPLATE';
import { MUTATE_CHECK_COMPUTE_STATUS } from '../../infrastructure/ApolloClient/requests/MUTATE_CHECK_COMPUTE_STATUS';
import { MUTATE_DATASET_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_DATASET_MANAGEMENT';
import { MUTATE_INVENTORY_EXTRA_EDIT } from '../../infrastructure/ApolloClient/requests/MUTATE_INVENTORY_EXTRA_EDIT';
import { MUTATE_LOCK_DATASET } from '../../infrastructure/ApolloClient/requests/MUTATE_LOCK_DATASET';
import { MUTATE_REQUEST_CALCULATION } from '../../infrastructure/ApolloClient/requests/MUTATE_REQUEST_CALCULATION';
import { QUERY_DATASETS } from '../../infrastructure/ApolloClient/requests/QUERY_DATASETS';
import { QUERY_IS_QUALITY_EMPTY } from '../../infrastructure/ApolloClient/requests/QUERY_IS_QUALITY_EMPTY';
import { QUERY_INVENTORY_EXTRA } from '../../infrastructure/ApolloClient/requests/QUERY_INVENTORY_EXTRA';
import { IMutateCreateDatasetFromTemplateInput } from '../interfaces/IMutateCreateDatasetFromTemplate';
import { ILaunchCompute } from '../interfaces/ILaunchCompute';
import { IMutateCheckComputeStatusOutput } from '../interfaces/IMutateCheckComputeStatus';
import { IMutateDatasetManagementInput, } from '../interfaces/IMutateDatasetManagement';
import { ILockDataset } from '../interfaces/ILockDataset';
import { IMutateRequestCalculationInput } from '../interfaces/IMutateRequestCalculation';
import { IQueryDatasetsInput } from '../interfaces/IQueryDatasets';
import { IQueryIsQualityEmptyInput } from '../interfaces/IQueryIsQualityEmpty';
import { IQueryInventoryExtraInput, IQueryInventoryExtraOutput } from '../interfaces/IQueryInventoryExtra';
import { IMutateInventoryExtraEditInput } from '../interfaces/IMutateInventoryExtraEdit';
import { MUTATE_COPY_DATASET } from '../../infrastructure/ApolloClient/requests/MUTATE_COPY_DATASET';
import { ICopyDataset } from '../interfaces/ICopyDataset';
import { MUTATE_FILTER_INDICATORS } from '../../infrastructure/ApolloClient/requests/MUTATE_FILTER_INDICATORS';
import { IMutateFilterIndicatorsInput } from '../interfaces/IMutateFilterIndicators';

export class DatasetsEntity extends Entity {
	public data: undefined | StudyDataset[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateCreateDatasetFromTemplate ??= this.app.installer(ApolloClientMutateCreateDatasetFromTemplate, MUTATE_CREATE_DATASET_FROM_TEMPLATE);
		this.app.adapter.mutateCheckComputeStatus ??= this.app.installer(ApolloClientMutateCheckComputeStatus, MUTATE_CHECK_COMPUTE_STATUS);
		this.app.adapter.mutateDatasetManagement ??= this.app.installer(ApolloClientMutateDatasetManagement, MUTATE_DATASET_MANAGEMENT);
		this.app.adapter.mutateFilterIndicators ??= this.app.installer(ApolloClientMutateFilterIndicators, MUTATE_FILTER_INDICATORS);
		this.app.adapter.mutateInventoryExtraEdit ??= this.app.installer(ApolloClientMutateEditInventoryExtra, MUTATE_INVENTORY_EXTRA_EDIT);
		this.app.adapter.mutateLockDataset ??= this.app.installer(ApolloClientMutateLockDataset, MUTATE_LOCK_DATASET);
		this.app.adapter.mutateRequestCalculation ??= this.app.installer(ApolloClientMutateRequestCalculation, MUTATE_REQUEST_CALCULATION);
		this.app.adapter.mutateCopyDataset ??= this.app.installer(ApolloClientMutateCopyDataset, MUTATE_COPY_DATASET);
		this.app.adapter.queryDatasets ??= this.app.installer(ApolloClientQueryDatasets, QUERY_DATASETS);
		this.app.adapter.queryIsQualityEmpty ??= this.app.installer(ApolloClientQueryIsQualityEmpty, QUERY_IS_QUALITY_EMPTY);
		this.app.adapter.queryInventoryExtra ??= this.app.installer(ApolloClientQueryInventoryExtra, QUERY_INVENTORY_EXTRA);
		this.app.adapter.storeDataDatasets?.({ loading: false, data: this, error: null });
	}

	get(ids: string[]): StudyDataset[] | undefined {
		if (!this.data || ids.some(id => !this.data?.find(b => b.id === id))) {
			if (this._isQuerying) return;
			const missingIds = ids.filter(id => !this.data?.find(b => b.id === id));
			this.queryDatasets({ datasetsIds: missingIds });
		}
		return this.data;
	}

	set(obj: StudyDataset[] | undefined) {
		if (!this.data || !obj) this.data = obj;
		else {
			obj.forEach(ds => {
				if (!this.data) return;
				const idx = this.data.findIndex(d => d.id === ds.id);
				if (idx === -1) {
					this.data.push(ds);
				} else {
					Object.assign(this.data[idx], ds);
				}
			});
		}
		this.change();
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		this.data.sort((a, b) => {
			if (a.name > b.name) return -1;
			if (a.name < b.name) return 1;
			return 0;
		});
		return this.app.adapter.storeDataDatasets?.({ loading: false, data: this, error: null });
	}

	update(dataset: StudyDataset): void {
		if (!dataset) return;
		const idx = this.data?.findIndex(d => d.id === dataset.id);
		if (idx === -1 || idx === undefined) return;
		if (!this.data) return;
	
		this.data[idx] = dataset;
		this.change();
	}

	updateResultStatus(id: string, status: StudyDataset['status']['results']): void {
		if (!this.data) return;
		const idx = this.data.findIndex(d => d.id === id);
		if (idx === -1) return;
		this.data[idx].status.results = status;
		this.change();
	}

	delete(id: string): void {
		if (!this.data) return;
		const idx = this.data.findIndex(d => d.id === id);
		if (idx === -1) return;
		this.data.splice(idx, 1);
		this.change();
	}

	deleteFromStudy(id: string): void {
		if (!this.data) return;
		this.data = this.data.filter(d => d.studyId !== id);
		this.change();
	}

	changeSelectedIndicators(id: string, indicators: string[]) {
		if (!this.data) return;
		const dataset = this.data.find(d => d.id === id);
		if (!dataset) return;
		dataset.selectedIndicators = indicators;
		this.change();
	}

	updateLockStatus(id: string, collectOpen: boolean, validationOpen: boolean): void {
		if (!this.data) return;
		const idx = this.data.findIndex(d => d.id === id);
		if (idx === -1) return;
		this.data[idx].permissions.collectOpen = collectOpen;
		this.data[idx].permissions.validationOpen = validationOpen;
		this.change();
	}

	updateDefinitiveResultStatus(id: string, status: boolean): void {
		if (!this.data) return;
		const idx = this.data.findIndex(d => d.id === id);
		if (idx === -1) return;
		this.data[idx].hasDefinitiveResults = status;
		this.change();
	}

	updateMaturityStatus(id: string, status: StudyDataset['status']['maturity']): void {
		if (!this.data) return;
		const idx = this.data.findIndex(d => d.id === id);
		if (idx === -1) return;
		this.data[idx].status.maturity = status;
		this.change();
	}

	private _storeError(error: Error) {
		this.app.adapter.storeDataDatasets?.({ loading: false, data: this, error });
	}

	setLoading(loading: boolean) {
		this.app.adapter.storeDataDatasets?.({ loading, data: this, error: null });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/
	async queryDatasets(input: IQueryDatasetsInput) {
		this.setLoading(true);
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryDatasets, input).catch((e) => {
			this._isQuerying = false;
			this._storeError(e);
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.datasets);
	}

	async mutateCreateDatasetFromTemplate(input: IMutateCreateDatasetFromTemplateInput): Promise<StudyDataset> {
		const data = await this.callApi(this.app.adapter.mutateCreateDatasetFromTemplate, input);
		this.set([data.mutateCreateDatasetFromTemplate.dataset]);
		this.app.entities.studies.addDatasetId(data.mutateCreateDatasetFromTemplate.dataset.studyId, data.mutateCreateDatasetFromTemplate.dataset.id);
		return data.mutateCreateDatasetFromTemplate.dataset;
	}

	async mutateCheckComputeStatus(input: ILaunchCompute): Promise<IMutateCheckComputeStatusOutput['mutateCheckComputeStatus']> {
		const data = await this.callApi(this.app.adapter.mutateCheckComputeStatus, input);
		return data.mutateCheckComputeStatus;
	}

	async mutateDatasetManagement(input: IMutateDatasetManagementInput) {
		const data = await this.callApi(this.app.adapter.mutateDatasetManagement, input).catch((e) => {
			if (e.message.includes('UNIQUE constraint failed')) throw new Error('Duplicate error');
			else throw new Error(this.errorMessage);
		});
		if (data.mutateDatasetManagement.status !== 200) {
			if (data.mutateDatasetManagement.error && data.mutateDatasetManagement.error.length > 0) throw new Error(data.mutateDatasetManagement.error);
			else throw new Error('Something went wrong');
		}

		if (input.remove) {
			this.delete(input.id);
			if (input.studyId) this.app.entities.studies.deleteDatasetId(input.studyId, input.id);
		} else {
			this.set([data.mutateDatasetManagement.dataset]);
			this.app.entities.studies.addDatasetId(data.mutateDatasetManagement.dataset.studyId, data.mutateDatasetManagement.dataset.id);
		}
	}

	async mutateFilterIndicators(input: IMutateFilterIndicatorsInput): Promise<void> {
		const data = await this.callApi(this.app.adapter.mutateFilterIndicators, input);
		if (data.mutateFilterIndicators.status !== 200) {
			throw new Error(this.errorMessage);
		}
		this.update(data.mutateFilterIndicators.dataset);
	}

	// TODO Tanguy: This mutation return a ds, but we don't need to carry all the data of the ds
	async mutateLockDataset(input: ILockDataset) {
		const data = await this.callApi(this.app.adapter.mutateLockDataset, input);
		if (data.mutateLockDataset.status !== 200) {
			throw new Error('Dataset not found');
		}
		this.update(data.mutateLockDataset.dataset);
	}

	async mutateRequestCalculation(input: IMutateRequestCalculationInput) {
		const data = await this.callApi(this.app.adapter.mutateRequestCalculation, input);
		if (data.mutateRequestCalculation.status !== 200) {
			throw new Error(this.errorMessage);
		}
		return true;
	}

	async mutateCopyDataset(input: ICopyDataset) {
		const data = await this.callApi(this.app.adapter.mutateCopyDataset, input);
		if (!data || data.mutateCopyDataset.status !== 200) {
			throw new Error(data.mutateCopyDataset.error ?? 'Error while copying dataset');
		}
		this.set([data.mutateCopyDataset.newDataset]);
		this.app.entities.studies.addDatasetId(data.mutateCopyDataset.newDataset.studyId, data.mutateCopyDataset.newDataset.id);
		return true;
	}

	async queryIsQualityEmpty(input: IQueryIsQualityEmptyInput) {
		return this.callApi(this.app.adapter.queryIsQualityEmpty, input);
	}

	// TODO Later Tanguy: We need to keep datas in entity here
	async queryInventoryExtra(input: IQueryInventoryExtraInput): Promise<IQueryInventoryExtraOutput['inventoryExtra']> {
		const data = await this.callApi(this.app.adapter.queryInventoryExtra, input);
		return data.inventoryExtra;
	}

	// TODO Later Tanguy: And update it here
	async mutateInventoryExtraEdit(input: IMutateInventoryExtraEditInput) {
		const data = await this.callApi(this.app.adapter.mutateInventoryExtraEdit, input);
		if (data.mutateInventoryExtraEdit.status !== 200) {
			throw new Error('No data InventoryExtra');
		}
		return true;
	}
}
