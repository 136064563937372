import { gql } from '@apollo/client';

export const MUTATE_DATASET_MANAGEMENT = gql`
    mutation MutateDatasetManagement(
        $id: String!,
        $name: String,
        $studyId: String,
        $validationSampleId: String,
        $remove: Boolean,
        $desc: String,
        $archived: Boolean,
        $selectedIndicators: [String]
    ) {
        mutateDatasetManagement(
            id: $id,
            name: $name,
            studyId: $studyId,
            validationSampleId: $validationSampleId,
            remove: $remove,
            desc: $desc,
            archived: $archived,
            selectedIndicators: $selectedIndicators
        ) {
            status,
            error,
            dataset {
                id,
                name,
                desc,
                archived,
                studyId,
                status {
                    maturity,
                    inventory,
                    validated,
                    results
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validationOpen,
                    validate,
                    results
                },
                selectedIndicators
            	hasDefinitiveResults
            	hasSettings
            }
        }
    }
`;
