import { gql } from '@apollo/client';

export const MUTATE_COPY_DATASET = gql`
    mutation CopyDataset(
        $studyId: String!,
        $datasetId: String!,
        $name: String!,
        $empty: Boolean,
        $desc: String!,
        $maturity: Boolean!
    ) {
        mutateCopyDataset(
            studyId: $studyId,
            datasetId: $datasetId,
            name: $name,
            empty: $empty,
            desc: $desc,
            maturity: $maturity
        ) {
            status,
            studyId,
            error,
            newDataset {
                id,
                studyId,
                name,
                desc,
                archived,
                status {
                    maturity,
                    inventory,
                    validated,
                    results
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validate,
                    results,
                    validationOpen
                }
                hasDefinitiveResults
                hasSettings
            }
        }
    }
`;
