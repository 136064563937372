/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ResponseUserData } from '../interfaces/ResponseUserData';
import { Entity } from '../core/Entity';
import {
	ApolloClientMutateAcceptCgu,
	ApolloClientMutateLoginUser,
	ApolloClientMutateReportError, ApolloClientMutateSendSuggestion,
	ApolloClientMutateSocialAuthentication,
	ApolloClientMutateUserHasSeenUpdate,
	ApolloClientMutateUserManagement,
	ApolloClientMutateUserPassword,
	ApolloClientMutateUserPasswordReset,
	ApolloClientMutateUserPasswordSendReset,
	ApolloClientQueryGroups,
	ApolloClientQueryLogoutError,
	ApolloClientQueryUpdate,
	ApolloClientQueryUserConnected,
	ApolloClientQueryUserConnectedVerify,
} from '../../infrastructure/ApolloClass/UserClass';
import { MUTATE_TOKENAUTH } from '../../infrastructure/ApolloClient/requests/MUTATE_TOKENAUTH';
import { MUTATE_ACCEPT_CGU } from '../../infrastructure/ApolloClient/requests/MUTATE_ACCEPT_CGU';
import { MUTATE_PASSWORD_CHANGE } from '../../infrastructure/ApolloClient/requests/MUTATE_PASSWORD_CHANGE';
import { MUTATE_PASSWORD_RESET } from '../../infrastructure/ApolloClient/requests/MUTATE_PASSWORD_RESET';
import { MUTATE_PASSWORD_SEND_RESET } from '../../infrastructure/ApolloClient/requests/MUTATE_PASSWORD_SEND_RESET';
import { MUTATE_USER_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_USER_MANAGEMENT';
import { QUERY_USER_CONNECTED_VERIFY } from '../../infrastructure/ApolloClient/requests/QUERY_USER_CONNECTED_VERIFY';
import { QUERY_USER } from '../../infrastructure/ApolloClient/requests/QUERY_USER';
import { QUERY_LOGOUT_ERROR_TEST } from '../../infrastructure/ApolloClient/requests/QUERY_LOGOUT_ERROR_TEST';
import { MUTATE_SOCIAL_AUTHENTICATION } from '../../infrastructure/ApolloClient/requests/MUTATE_SOCIAL_AUTHENTICATION';
import { MUTATE_ERROR_REPORT } from '../../infrastructure/ApolloClient/requests/MUTATE_ERROR_REPORT';
import { QUERY_GROUPS } from '../../infrastructure/ApolloClient/requests/QUERY_GROUPS';
import { QUERY_UPDATE } from '../../infrastructure/ApolloClient/requests/QUERY_UPDATE';
import { MUTATE_USER_HAS_SEEN_UPDATE } from '../../infrastructure/ApolloClient/requests/MUTATE_USER_HAS_SEEN_UPDATE';
import { MUTATE_SEND_SUGGESTIONS } from '../../infrastructure/ApolloClient/requests/MUTATE_SEND_SUGGESTIONS';import { EConnectionStatus } from '../interfaces/EConnectionStatus';
import { ICredentials } from '../interfaces/ICredentials';
import { IUserPasswords } from '../interfaces/IUserPasswords';
import { IUserPasswordReset } from '../interfaces/IUserPasswordReset';
import { IUserPasswordSendReset } from '../interfaces/IUserPasswordSendReset';
import { IMutateSocialAuthenticationInput } from '../interfaces/IMutateSocialAuthentication';
import { IGroup } from '../interfaces/IQueryGroups';
import { IQueryUpdateOutput } from '../interfaces/IQueryUpdate';
import { IUserManagement } from '../interfaces/ResponseCompanyUsers';
import { IAcceptCgu } from '../interfaces/IMutateAcceptCgu';
import { IMutateSendSuggestionInput } from '../interfaces/IMutateSendSuggestion';
import { changeModalStatusData } from '../../store/dispatchers';

export class UserEntity extends Entity {
	public data: undefined | ResponseUserData['me'];
	public status: EConnectionStatus = EConnectionStatus.PENDING;
	private interval: ReturnType<typeof setInterval> | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateLoginUser ??= this.app.installer(ApolloClientMutateLoginUser, MUTATE_TOKENAUTH);
		this.app.adapter.mutateAcceptCgu ??= this.app.installer(ApolloClientMutateAcceptCgu, MUTATE_ACCEPT_CGU);
		this.app.adapter.mutatePasswords ??= this.app.installer(ApolloClientMutateUserPassword, MUTATE_PASSWORD_CHANGE);
		this.app.adapter.mutatePasswordReset ??= this.app.installer(ApolloClientMutateUserPasswordReset, MUTATE_PASSWORD_RESET);
		this.app.adapter.mutatePasswordSendReset ??= this.app.installer(ApolloClientMutateUserPasswordSendReset, MUTATE_PASSWORD_SEND_RESET);
		this.app.adapter.mutateReportError ??= this.app.installer(ApolloClientMutateReportError, MUTATE_ERROR_REPORT);
		this.app.adapter.mutateSendSuggestion ??= this.app.installer(ApolloClientMutateSendSuggestion, MUTATE_SEND_SUGGESTIONS);
		this.app.adapter.mutateSocialAuthentication ??= this.app.installer(ApolloClientMutateSocialAuthentication, MUTATE_SOCIAL_AUTHENTICATION);
		this.app.adapter.mutateUserManagement ??= this.app.installer(ApolloClientMutateUserManagement, MUTATE_USER_MANAGEMENT);
		this.app.adapter.mutateUserHasSeenUpdate ??= this.app.installer(ApolloClientMutateUserHasSeenUpdate, MUTATE_USER_HAS_SEEN_UPDATE);
		this.app.adapter.queryUpdate ??= this.app.installer(ApolloClientQueryUpdate, QUERY_UPDATE);
		this.app.adapter.queryUserConnectedVerify ??= this.app.installer(ApolloClientQueryUserConnectedVerify, QUERY_USER_CONNECTED_VERIFY);
		this.app.adapter.queryUser ??= this.app.installer(ApolloClientQueryUserConnected, QUERY_USER);
		this.app.adapter.queryLogoutErrorTest ??= this.app.installer(ApolloClientQueryLogoutError, QUERY_LOGOUT_ERROR_TEST);
		this.app.adapter.queryGroups ??= this.app.installer(ApolloClientQueryGroups, QUERY_GROUPS);
		this.app.adapter.storeUserDTO?.({ entity: this, data: undefined, error: undefined });
	}

	get(): ResponseUserData['me'] | undefined {
		if (this.status === EConnectionStatus.PENDING) {
			if (this._isQuerying) return;
			this.queryUser();
		}
		return this.data;
	}

	set(obj: ResponseUserData['me'] | undefined): void {
		if (!obj) {
			this.data = undefined;
			this.setStatus(EConnectionStatus.DISCONNECTED);
			this.change();
			return;
		}
		this.data = obj;
		this.setStatus(EConnectionStatus.CONNECTED);
		this.verifyInternal();
		this.change();
	}

	clear(): void {
		// Call set method of all entities to clear their data, as undefined
		Object.values(this.app.entities).forEach((entity: Entity) => entity.set?.(undefined));

		// Clear notifications store
		this.app.adapter.storeNotification?.(undefined);

		// Clear Error store
		this.app.adapter.storeError?.(null);
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeUserDTO?.({
			entity: this,
			data: this.data,
			error: undefined,
		});
	}

	update(): void {
		return;
	}

	/**
	 * Verify the user connection every 5 minutes.
	 * if user data is not available, clear existing interval.
	 * if user data is available and no interval currently running, set new interval.
	 */
	verifyInternal(): void {
		if (this.interval && !this.data) {
			clearInterval(this.interval);
		} else if (!this.interval && this.data) {
			const time = 5 * 1000 * 60; // 5 minutes
			this.interval = setInterval(() => this.verify(), time);
		}
	}

	/**
	 * Verify the user connection.
	 */
	verify(): void {
		this.queryUserConnectedVerify().then();
	}

	setStatus(status: EConnectionStatus): void {
		this.status = status;
		this.app.adapter.storeEvent?.emit('user:status', status);
	}

	getLanguage(): string {
		return this.app.adapter.getLang?.() ?? 'en';
	}

	getOtherLanguage = (): string => {
		const lang = this.app.adapter.getLang?.();
		return lang === 'en' ? 'fr' : 'en';
	};

	async setLanguage(lang: string): Promise<void> {
		this.app.adapter.setLang?.(lang);
	}


	private _storeError(type: 'login' | 'network' | 'server' | string | undefined) {
		this.set(undefined);
		this.app.adapter.storeUserDTO?.({
			entity: this,
			data: undefined,
			error: type,
		});
		this.setStatus(EConnectionStatus.DISCONNECTED);
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryLogoutErrorTest() {
		return this.callApi(this.app.adapter.queryLogoutErrorTest).catch(() => undefined);
	}

	async queryUpdate(): Promise<IQueryUpdateOutput> {
		return this.callApi(this.app.adapter.queryUpdate);
	}

	async queryUserLogout() {
		await this.app.adapter.setToken?.('');

		// Clear Store
		this.clear();

		// Call the adapter to clear the user data, then set status to disconnected to be able to return to login page
		this.setStatus(EConnectionStatus.DISCONNECTED);
		await this.app.adapter.storeUserDTO?.({
			entity: this,
			data: undefined,
			error: undefined,
		});
	}

	async queryUserConnectedVerify() {
		const data = await this.callApi(this.app.adapter.queryUserConnectedVerify).catch(() => undefined);
		if (!data || !data.me) {
			this._storeError(undefined);
			return;
		}
	}

	async queryUser(): Promise<ResponseUserData | undefined> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryUser).catch(() => {
			this._isQuerying = false;
			this._storeError(undefined);
			return undefined;
		});
		this._isQuerying = false;
		if (!data || !data.me) {
			// Clear Store
			this.clear();
			this.set(undefined);
			return;
		}
		this.set(data.me);
		return data;
	}

	async queryGroups(): Promise<IGroup[]> {
		const data = await this.callApi(this.app.adapter.queryGroups);
		return data.groups;
	}

	async mutateUserLogin(input: ICredentials): Promise<{ cguToken?: string, error?: string, success: boolean }> {
		let err = null;
		const data = await this.callApi(this.app.adapter.mutateLoginUser, input).catch((e) => {
			err = e;
		});

		// @ts-ignore
		if (err && err instanceof Error) {
			// @ts-ignore
			if (err.message?.startsWith('Too many attempts')) {
				// @ts-ignore
				this._storeError(err.message);
			} else {
				this._storeError('network');
			}
			return { success: false, error: 'network' };
		}
		if (data && data.mutateTokenAuth.errors) {
			if (data.mutateTokenAuth.errors.includes('MustAcceptCgu')) {
				this._storeError('cguMustBeAccepted');
				return { success: false, cguToken: data.mutateTokenAuth.token, error: 'cguMustBeAccepted' };
			}
			if (data.mutateTokenAuth.errors.includes('CguNotAcceptedByCompany')) {
				this._storeError('cguNotAccepted');
				return { success: false, error: 'cguNotAccepted' };
			}
		}

		if (!data || !data.mutateTokenAuth.token) {
			this._storeError('login');
			return { success: false, error: 'login' };
		}

		this.clear();
		const token = data.mutateTokenAuth.token;
		const { lang, adapter } = await this.getApiProps();
		const user = await this.app.adapter.queryUser?.({ token, lang, adapter }).catch(() => undefined);
		if (!user) {
			this._storeError('login');
			return { success: false, error: 'login' };
		}

		this.app.adapter.setToken?.(token);
		this.set(user.me);
		await this.app.adapter.storeUserDTO?.({
			entity: this,
			data: user.me,
			error: undefined,
		});
		
		// Clean modal store
		changeModalStatusData({ loading: false, error: null, data: undefined });

		// Add notification if the user has not seen updates
		if (!user.me.hasSeenUpdate) {
			this.app.adapter.storeUserNotification?.({
				title: 'home.notification.newUpdate.title',
				message: 'home.notification.newUpdate.message',
				redirect: '/?update=true',
				additionalCloseAction: () => this.mutateUserHasSeenUpdate()
			});
		}

		return { success: true };
	}

	async mutateAcceptCgu(input: IAcceptCgu): Promise<void> {
		const data = await this.callApi(this.app.adapter.mutateAcceptCgu, input);
		if (!data || data.mutateAcceptCgu.error) {
			if (data?.mutateAcceptCgu.error?.length) {
				this._storeError(data.mutateAcceptCgu.error);
				throw new Error(data.mutateAcceptCgu.error);
			}
		}
		const token = data.mutateAcceptCgu.authToken;
		if (!token){
			this._storeError(data.mutateAcceptCgu.error);
			throw new Error('No token');
		}
		this.app.adapter.setToken?.(token);
		const { lang, adapter } = await this.getApiProps();
		const user = await this.app.adapter.queryUser?.({ token, lang, adapter }).catch(() => undefined);
		if (!user) {
			this._storeError('login');
			throw new Error('login');
		}
		this.app.adapter.setToken?.(token);
		this.set(user.me);
		await this.app.adapter.storeUserDTO?.({
			entity: this,
			data: user.me,
			error: undefined,
		});
	}

	async mutateUserPassword(input: IUserPasswords): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutatePasswords, input);
		if (!data || !data.mutatePasswordChange.token || data.mutatePasswordChange.errors) {
			if (data?.mutatePasswordChange.errors?.length) {
				this.errorMessage = data.mutatePasswordChange.errors.join('; ');
			}
			throw new Error(this.errorMessage);
		}
		await this.app.adapter.setToken?.(data.mutatePasswordChange.token);
		return true;
	}

	async mutateUserPasswordReset(input: IUserPasswordReset): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutatePasswordReset, input);
		if (!data || data.mutatePasswordReset.errors) {
			if (data?.mutatePasswordReset.errors?.length) {
				this.errorMessage = data.mutatePasswordReset.errors.join('; ');
			}
			throw new Error(this.errorMessage);
		}
		return data.mutatePasswordReset.status === 200;
	}

	async mutateUserPasswordSendReset(input: IUserPasswordSendReset) {
		const data = await this.callApi(this.app.adapter.mutatePasswordSendReset, input);
		if (!data || data.mutateSendPasswordResetEmail.errors) {
			if (data?.mutateSendPasswordResetEmail.errors?.length) {
				this.errorMessage = data.mutateSendPasswordResetEmail.errors.join('; ');
			}
			throw new Error(this.errorMessage);
		}
		return data.mutateSendPasswordResetEmail.status === 200;
	}

	async mutateSocialAuthentication(input: IMutateSocialAuthenticationInput) {
		const data = await this.callApi(this.app.adapter.mutateSocialAuthentication, input).catch(undefined);
		if (data.mutateSocialAuth.status !== 200 || data.mutateSocialAuth.errors) {
			const errors = data.mutateSocialAuth.errors.join(', ');
			if (errors.length === 0) this._storeError('login');
			else this._storeError(errors);
			return false;
		}
		const newToken = data.mutateSocialAuth.token;
		const { adapter, lang } = await this.getApiProps();
		const user = await this.app.adapter.queryUser?.({ token: newToken, lang, adapter }).catch(() => undefined);
		if (!user) {
			this._storeError('login');
			return false;
		}

		await this.app.adapter.setToken?.(newToken);
		this.set(user.me);
		await this.app.adapter.storeUserDTO?.({
			entity: this,
			data: user.me,
			error: undefined,
		});
		return true;
	}

	async mutateReportError(error: Error, { componentStack }: { componentStack: string }, message: string, channel?: string) {
		await this.callApi(this.app.adapter.mutateReportError, {
			errorName: error.name,
			errorMessage: error.message,
			errorStack: error.stack ?? '',
			componentStack,
			topic: message,
			channel: channel,
		});
	}

	async mutateUserManagement(input: IUserManagement) {
		const data = await this.callApi(this.app.adapter.mutateUserManagement, input);
		if (!data || !data.mutateUserManagement || data.mutateUserManagement.status !== 200) {
			return false;
		}
		if (input.remove) this.app.entities.company.deleteUser(input.id);
		else if (input.id === 'new') this.app.entities.company.addUser(data.mutateUserManagement.user);
		else this.app.entities.company.updateUser(data.mutateUserManagement.user);
		return true;
	}

	async mutateUserHasSeenUpdate(): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateUserHasSeenUpdate);
		if (data.mutateUserHasSeenUpdate.status !== 200) return false;
		if (this.data) {
			this.data.hasSeenUpdate = true;
			this.change();
		}
		return true;
	}

	async mutateSendSuggestion(input: IMutateSendSuggestionInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateSendSuggestion, input);
		if (!data || !data.mutateSendSuggestion || data.mutateSendSuggestion.status !== 200) {
			return false;
		}
		return data.mutateSendSuggestion.status === 200;
	}
}
