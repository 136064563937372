import { IBlock } from '../domain/interfaces/IBlock';
import { IIndicator } from '../domain/interfaces/IIndicator';
import { ILifeCycleStep } from '../domain/interfaces/IQueryInventoryExtraContent';
import { ResultArrowComparisonType, ResultComparison } from '../viewComponents/results/ResultArrowComparison';
import { ISimpleEquipment } from '../domain/interfaces/IQueryEquipmentByIds';
import { IMaturityDomain } from '../domain/interfaces/IMaturityDomain';
import { getBlockName, getIconByIndicatorId, getIndicatorName, getUnitByIndicatorId } from './getNamesByIds';
import { EquipmentType } from '../domain/data/entries/EquipmentType';
import { EquipmentTypeCategory } from '../domain/data/entries/EquipmentTypeCategory';
import { unique } from '../viewComponents/results/utils/Unique';
import { IEquivalence } from '../domain/interfaces/IEquivalence';
import { MaturityDataEntity } from '../domain/entities/MaturityEntity';
import { IndicatorEntity } from '../domain/entities/IndicatorEntity';
import { ManageEquipmentEntity } from '../domain/entities/ManageEquipmentEntity';

export interface IResultTableDataInput {
	indicator?: string;
	indicatorShort?: string;
	indicatorColor?: string;
	icon?: string;
	life_cycle_step?: string;
	life_cycle_stepColor?: string;
	block?: string;
	blockColor?: string;
	domain?: string;
	scope?: string;
	unit?: string;
	equivalence?: string;
	equivalenceHypothesis?: string;
	equipment?: string;
	equipment_type?: string;
	category?: string;
	specification?: string;
	quality?: number;
	quality_weight?: number;
	percentage?: number;
	year?: number;
	'business-as-usual'?: number;
	ideal?: number;
	value?: number;
	grade?: number;
	mean?: number;
	max?: number;
	min?: number;
	indicator_pb?: number,
	total?: number;
	totalValue?: number;
	scope_id?: string;
	bges_unit_id?: string;
	ademe_carbon_footprint?: string;
	ghg_protocol_category?: string;
}

export interface IResultTableDataOutput extends Omit<IResultTableDataInput, 'equipment'|'equipment_type'> {
	equipment?: ISimpleEquipment;
	equipment_type?: EquipmentType;
}

/**
 * @param data : IResultTableDataInput[]
 * @param blocks : IBlock[] | undefined
 * @param indicators : IIndicator[] | undefined
 * @param lifeCycleSteps : ILifeCycleStep[] | undefined
 * @param maturityEntity : MaturityDataEntity | undefined
 * @param indicatorEntity : IndicatorEntity | undefined
 * @param manageEquipmentEntity : ManageEquipmentEntity | undefined
 * 
 * @returns IResultTableDataOutput[]
 * @description This function is used to transform the result data from ID's to names and units.
 * @author Yacine Bentayeb
 * */
export  const transformResultTableData = async (
	data: IResultTableDataInput[],
	blocks: IBlock[] | undefined,
	indicators: IIndicator[] | undefined,
	lifeCycleSteps: ILifeCycleStep[] | undefined,
	maturityEntity: MaturityDataEntity | undefined,
	indicatorEntity: IndicatorEntity | undefined,
	manageEquipmentEntity: ManageEquipmentEntity | undefined
) : Promise<IResultTableDataOutput[] | Error>  => {

	let equipments: ISimpleEquipment[] | null | undefined;
	let equipments_types: EquipmentType[] | null | undefined;
	let categories: EquipmentTypeCategory[] | null | undefined;
	let domains: IMaturityDomain[] | undefined;
	let equivalences: IEquivalence[] | undefined;

	if (data && data.length > 0 && data[0].equipment != undefined) {

		const equipmentIds = data.map((item) => {
			return item.equipment ?? '';
		});
		equipments = (await manageEquipmentEntity?.queryEquipmentByIds({ ids: equipmentIds }).catch(() => undefined))?.equipmentByIds;
		if (!equipments || equipments.length === 0) return new Error('No equipment found');
	} else {
		equipments = null;
	}

	if (data && data.length > 0 && data[0].equipment_type != undefined) {
		const equipmentTypessInfo = await manageEquipmentEntity?.queryEquipmentTypes({ idList: data.map((d) => d.equipment_type ?? '').filter(unique) }).catch(() => undefined);
		equipments_types = equipmentTypessInfo?.equipmentTypes;
		if (!equipments_types || equipments_types.length === 0) return new Error('No equipment types found');
		categories = await manageEquipmentEntity?.queryEquipmentTypeCategories().catch(() => undefined);
		if (!categories || categories.length === 0) return new Error('No equipment categories found');
		const equipmentTypesIds = equipments_types.map((item) => {
			return item.id ?? '';
		});
		equipments_types = equipments_types.filter((item) => {
			return equipmentTypesIds.includes(item.id);
		});
		const categoriesIds = categories.map((item) => {
			return item.id ?? '';
		});
		categories = categories.filter((item) => {
			return categoriesIds.includes(item.id);
		});
	} else {
		equipments_types = null;
		categories = null;
	}

	if (data && data.length > 0 && data[0].domain != undefined) {
		domains = (await maturityEntity?.queryMaturityDomains().catch(() => undefined));
		if (!domains || domains.length === 0) return new Error('No domains found');
	}

	if (data && data.length > 0 && data[0].equivalence != undefined) {
		equivalences = (await indicatorEntity?.queryEquivalences().catch(() => undefined));
		if (!equivalences || equivalences?.length === 0) return new Error('No equivalences found');
	}

	return data.filter((tableObject) => {
		// If there's no indicator in the tableObject, we include it by default
		if (!tableObject.indicator) {
			return true;
		}
		// We filter out the tableObject if the indicator is not in the indicators array
		// (indicators are filtered in the result view component)
		return indicators?.some((indicator) => indicator.id === tableObject.indicator) || tableObject.indicator === 'normalized';
	}).sort((a, b) => {
		if (indicators && a.indicator && b.indicator) {
			const indicatorA = indicators.find((indicator) => indicator.id === a.indicator);
			const indicatorB = indicators.find((indicator) => indicator.id === b.indicator);
			if (indicatorA && indicatorB && indicatorA.order !== indicatorB.order) {
				if (indicatorA.order < indicatorB.order) return -1;
				if (indicatorA.order > indicatorB.order) return 1;
			}
		}
		if (blocks && a.block && b.block) {
			const blockA = blocks?.find((block) => block.id === a.block);
			const blockB = blocks?.find((block) => block.id === b.block);
			if (blockA && blockB && blockA.order !== blockB.order) {
				if (blockA.order < blockB.order) return -1;
				if (blockA.order > blockB.order) return 1;
			}
		}
		return 0;
	}).map(({ equipment, equipment_type, ...tableObject }: IResultTableDataInput) => {
		const outputTableObject: IResultTableDataOutput = {
			...tableObject,
		};

		if (blocks && tableObject.block) {
			outputTableObject.block = getBlockName(tableObject.block, blocks) ?? tableObject.block;
			outputTableObject.blockColor = blocks.find((block) => block.id === tableObject.block)?.color ?? tableObject.blockColor;	
		}
		if (indicators && tableObject.indicator) {
			outputTableObject.icon = getIconByIndicatorId(tableObject.indicator, indicators) ?? tableObject.icon;
			outputTableObject.indicator = getIndicatorName(tableObject.indicator, indicators) ?? tableObject.indicator;
			outputTableObject.unit = getUnitByIndicatorId(tableObject.indicator, indicators) ?? tableObject.unit;
			outputTableObject.indicatorShort = indicators.find((indicator) => indicator.id === tableObject.indicator)?.shortName ?? tableObject.indicatorShort;
			outputTableObject.indicatorColor = indicators.find((indicator) => indicator.id === tableObject.indicator)?.color ?? tableObject.indicatorColor;
		}
		if (lifeCycleSteps && tableObject.life_cycle_step) {
			outputTableObject.life_cycle_step = lifeCycleSteps.find((step) => step.id === tableObject.life_cycle_step)?.code ?? tableObject.life_cycle_step;
			outputTableObject.life_cycle_stepColor = lifeCycleSteps.find((step) => step.id === tableObject.life_cycle_step)?.color ?? tableObject.life_cycle_stepColor;
		}
		if (equipment) {
			outputTableObject.equipment = equipments?.find((equip) => equip.id === equipment);
			outputTableObject.category = equipments?.find((equip) => equip.id === equipment)?.category ?? tableObject.category;
		}
		if(equipment_type) {
			outputTableObject.equipment_type = equipments_types?.find((equip) => equip.id === equipment_type);
			outputTableObject.category = categories?.find((category) => category.id === outputTableObject.equipment_type?.category)?.name ?? tableObject.category;
		}

		if (tableObject.domain) {
			outputTableObject.domain = domains?.find((domain) => domain.id === tableObject.domain)?.name ?? tableObject.domain;
			outputTableObject.scope = domains?.find((domain) => domain.id === tableObject.domain)?.scope ?? tableObject.scope;
		}

		if (tableObject.equivalence) {
			outputTableObject.equivalence = equivalences?.find((equivalence) => equivalence.id === tableObject.equivalence)?.name ?? tableObject.equivalence;
			outputTableObject.equivalenceHypothesis = equivalences?.find((equivalence) => equivalence.id === tableObject.equivalence)?.hypothesis ?? tableObject.equivalenceHypothesis;
			outputTableObject.icon = equivalences?.find((equivalence) => equivalence.id === tableObject.equivalence)?.icon ?? tableObject.icon;
		}

		if (tableObject.bges_unit_id) {
			outputTableObject.scope = tableObject.scope ?? '-';
			outputTableObject.scope_id = tableObject.scope_id ?? '-';
			outputTableObject.bges_unit_id = tableObject.bges_unit_id;
			outputTableObject.ademe_carbon_footprint = tableObject.ademe_carbon_footprint ?? '-';
			outputTableObject.ghg_protocol_category = tableObject.ghg_protocol_category ?? '-';
		}

		return outputTableObject;
	});
};

/**
 * A generic function to prepare the data for result tables.
 *
 * @param transformedData - An array of IResultTableData objects.
 * @param getKey - A function that extracts the key (e.g., life_cycle_step, block) from the IResultTableData object.
 * @returns An array of table data objects with the structure { indicator, unit, values[] }.
 *
 * This function takes an array of IResultTableData objects and a function to extract the key from them.
 * It then prepares the table data by grouping the IResultTableData objects by their indicator values
 * and storing the key, value, and arrow data in the values array.
 *
 * The getKey function should be provided according to the specific use case. For example:
 * - For the case with `block` as the key: (data) => data.block ?? ''
 * - For the case with `life_cycle_step` as the key: (data) => data.life_cycle_step ?? ''
 *
 * Example usage:
 * const table = prepareDataTableResult(transformedData, (data) => data.block ?? '');
 */
export type TableDataType = {
	indicator: string;
	icon?: string;
	unit?: string;
	values: {
		key: string;
		value: number;
		color?: string;
		arrow?: ResultArrowComparisonType;
	}[];
};
export const prepareDataTableResult = (
	transformedData: IResultTableDataOutput[],
	getKey: (data: IResultTableDataOutput) => string
): TableDataType[] => {
	const table: TableDataType[] = [];

	// Iterate through each item in the transformedData array
	for (const d of transformedData) {
		// Calculate the arrow value using the ResultComparison function
		let arrow;
		if (d.value !== undefined ){
			if (d.value) {
				arrow = ResultComparison({
					value: d.value,
					mean: d.mean,
					min: d.min,
					max: d.max,
				});
			}
		}
		// Get the key value using the provided getKey function
		const key = getKey(d);

		// Find the table item with the same indicator value
		const tableItem = table.find((t) => t.indicator === d.indicator);

		// If the table item doesn't exist, create a new table item with the current data
		if (!tableItem) {
			table.push({
				icon: d.icon ?? '',
				indicator: d.indicator ?? '',
				unit: d.unit,
				values: [{ key, value: d.value ?? 0, arrow }],
			});
		} else {
			// If the table item exists, add the current key, value, and arrow data to its values array
			tableItem.values.push({ key, value: d.value ?? 0, arrow });
		}
	}
	return table;
};
  