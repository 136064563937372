import { gql } from '@apollo/client';

export const MUTATE_CREATE_DATASET_FROM_TEMPLATE = gql`
    mutation MutateCreateDatasetFromTemplate(
        $templateId: String!,
        $name: String!,
        $description: String!,
        $studyId: String!,
  ) {
    mutateCreateDatasetFromTemplate(
        templateId: $templateId,
        name: $name,
        description: $description,
        studyId: $studyId,
    ) {
        dataset {
        id
        studyId
        name
        desc
        archived
        status {
            maturity
            inventory
            validated
            results
            impactsComplete
        }
        metadata {
            prioEquipRate
            equipmentRate
            prioSettingRate
            settingRate
            maturityRate
        }
        permissions {
            collectOpen
            validate
            results
            validationOpen
        }
        validationSampleId
        hasDefinitiveResults
        hasSettings
        }
        }
    }
`;
