import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateEquipmentCategoriesOrderInput, IMutateEquipmentCategoriesOrderOutput } from '../../domain/interfaces/IMutateEquipmentCategoriesOrder';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { ResponseMutateEquipmentOrder } from '../../domain/data/ResponseMutateEquipmentOrder';
import { ResponseEquipmentTypeCategories } from '../../domain/data/ResponseEquipmentTypeCategories';
import { IQueryEquipmentTypeCategoriesInput } from '../../domain/interfaces/IQueryEquipmentTypeCategories';
import { ResponseEquipmentTypes } from '../../domain/data/ResponseEquipmentTypes';
import { IQueryEquipmentTypesInput } from '../../domain/interfaces/IQueryEquipmentTypes';
import { IQueryEquipmentByIdsInput, IQueryEquipmentByIdsOutput } from '../../domain/interfaces/IQueryEquipmentByIds';
import {
	IQuerySearchEquipmentExploreInput,
	IQuerySearchEquipmentExploreOutput
} from '../../domain/interfaces/IQuerySearchEquipmentExplore';
import {
	IQuerySearchEquipmentTypeInput,
	IQuerySearchEquipmentTypeOutput
} from '../../domain/interfaces/IQuerySearchEquipmentType';
import { IEquipmentOrder } from '../../domain/interfaces/IEquipmentOrder';
import {
	IQueryResilioDbSuggestionsInput,
	IQueryResilioDbSuggestionsOutput
} from '../../domain/interfaces/IQueryResilioDbSuggestions';
import { IQueryImpactFactorsInput, IQueryImpactFactorsOutput } from '../../domain/interfaces/IQueryImpactFactors';
import { IMutateEquipmentTypeManagementInput, IMutateEquipmentTypeManagementOutput } from '../../domain/interfaces/IMutateEquipmentTypeManagement';
import { IQueryCountriesOfUseResponse } from '../../domain/interfaces/IQueryCountriesOfUse';
import { IQueryModalEquipmentTypesInfosOutput } from '../../domain/interfaces/IQueryModalEquipmentTypesInfos';

export class ApolloClientMutateEquipmentCategoriesOrder extends ApolloClientProvider<IMutateEquipmentCategoriesOrderOutput, IMutateEquipmentCategoriesOrderInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEquipmentCategoriesOrderInput): Promise<IMutateEquipmentCategoriesOrderOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEquipmentOrder extends ApolloClientProvider<ResponseMutateEquipmentOrder, IEquipmentOrder> {
	exec({ token, lang, adapter }: IAPIProps, data: IEquipmentOrder): Promise<ResponseMutateEquipmentOrder | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryMutateEquipmentTypeManagement extends ApolloClientProvider<IMutateEquipmentTypeManagementOutput, IMutateEquipmentTypeManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEquipmentTypeManagementInput): Promise<IMutateEquipmentTypeManagementOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryEquipmentTypes extends ApolloClientProvider<ResponseEquipmentTypes, IQueryEquipmentTypesInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryEquipmentTypesInput): Promise<ResponseEquipmentTypes | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryImpactFactors extends ApolloClientProvider<IQueryImpactFactorsOutput, IQueryImpactFactorsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryImpactFactorsInput): Promise<IQueryImpactFactorsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryEquipmentByIds extends ApolloClientProvider<IQueryEquipmentByIdsOutput, IQueryEquipmentByIdsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryEquipmentByIdsInput): Promise<IQueryEquipmentByIdsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientQuerySearchEquipmentExplore extends ApolloClientProvider<IQuerySearchEquipmentExploreOutput, IQuerySearchEquipmentExploreInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQuerySearchEquipmentExploreInput): Promise<IQuerySearchEquipmentExploreOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryResilioDbSuggestions extends ApolloClientProvider<IQueryResilioDbSuggestionsOutput, IQueryResilioDbSuggestionsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryResilioDbSuggestionsInput): Promise<IQueryResilioDbSuggestionsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientQuerySearchEquipmentType extends ApolloClientProvider<IQuerySearchEquipmentTypeOutput, IQuerySearchEquipmentTypeInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQuerySearchEquipmentTypeInput): Promise<IQuerySearchEquipmentTypeOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryModalEquipmentTypesInfos extends ApolloClientProvider<IQueryModalEquipmentTypesInfosOutput, IQueryEquipmentTypesInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryEquipmentTypesInput): Promise<IQueryModalEquipmentTypesInfosOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryEquipmentTypeCategories extends ApolloClientProvider<ResponseEquipmentTypeCategories, IQueryEquipmentTypeCategoriesInput> {}

export class AppolloClientCountriesOfUse extends ApolloClientProvider<IQueryCountriesOfUseResponse> {}
