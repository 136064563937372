import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { GroupedResultTable, IGroupedResultTableProps } from './GroupedResultTable';
import { resultValue } from './utils/resultValue';
import { translate } from '../../infrastructure/translations/translate';
import { RelativeDoughnutChart } from '../graphs/doughnuts/RelativeDoughnutChart';
import { DoughnutPluginWritePercentage } from '../graphs/plugins/DoughnutPluginWritePercentage';

export type ResultBgesType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { scope: string, scope_id: string, bges_unit_id: string, ademe_carbon_footprint: string, ghg_protocol_category: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultBges = (o: any): o is ResultBgesType => {
	if (o && typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};

export const ResultBges = ({ data }: { data: string }): JSX.Element => {
	if (!isResultBges(data)) return (<ResultCardError/>);

	const transformedData = useTransformedResultTableData(data.data);
	const [dataTable, setDataTable] = useState<IGroupedResultTableProps | null>(null);

	const createScopeTitle = (scopeId: string | undefined, scope: string | undefined): React.ReactNode => {
		if (!scopeId || !scope) return <></>;
		return <span>
			<strong>Scope {scopeId}</strong>
			<br/>
			<span>{scope}</span>
		</span>;
	};

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// set the table for the ResultTable component
		const filteredData = transformedData.filter(d => d.value && d.value !== 0);
		const groupedData = filteredData.reduce((acc, val) => {
			const existingScope = acc.find(a => a.scopeId === val.scope_id);
			if (existingScope) {
				existingScope.total += val.value ?? 0;
				existingScope.rows.push([
					{ value: val.bges_unit_id },
					{ value: val.ademe_carbon_footprint },
					{ value: val.ghg_protocol_category },
					{ value: resultValue(val.value) }]);
			} else {
				acc.push({
					scope: createScopeTitle(val.scope_id, val.scope),
					scopeId: val.scope_id ?? '',
					total: val.value ?? 0,
					rows: [[
						{ value: val.bges_unit_id },
						{ value: val.ademe_carbon_footprint },
						{ value: val.ghg_protocol_category },
						{ value: resultValue(val.value) }]
					] });
			}
			return acc;
		}, [] as { scopeId: string, scope: string | React.ReactNode, total: number, rows: { value: string | undefined, valueType?: string, style?: CSSProperties }[][] }[]);
		setDataTable({
			columns: [
				{ name: translate('result.scopes') as string },
				{ name: translate('result.bgesUnitId') as string },
				{ name: translate('result.ademeCarbonFootprint') as string },
				{ name: translate('result.ghgProtocolCategory') as string },
				{ name: translate('result.ghg') as string },
				{ name: `${translate('total') as string} (kg CO2 eq.)` },
			],
			rows: groupedData
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <GroupedResultTable dataTable={dataTable} />;
};

export const BgesGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultBges(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	if (!transformedData || transformedData instanceof Error) return <ResultCardError/>;

	const filteredData = transformedData.filter(d => d.value && d.value !== 0);
	const groupedData = filteredData.reduce((acc, val) => {
		const existingScope = acc.find(a => a.scopeId === val.scope_id);
		if (existingScope) {
			existingScope.total += val.value ?? 0;
		} else {
			acc.push({
				scopeId: val.scope_id ?? '',
				scope: val.scope ?? '',
				total: val.value ?? 0,
			});
		}
		return acc;
	}, [] as { scopeId: string, scope: string, total: number }[]);

	const datasets = groupedData.map(d => ({ labels: `Scope ${d.scopeId} - ${d.scope}`, values: d.total }));

	return <RelativeDoughnutChart
		datasets={datasets}
		plugins={[DoughnutPluginWritePercentage()]}
	/>;
};
