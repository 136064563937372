import React from 'react';
import { resultValue } from './utils/resultValue';

type GroupedRow = {
	scope: string | React.ReactNode,
	scopeId: string,
	total: number, // Total of all value
	rows: {
		value: string | undefined,
		valueType?: string,
		style?: React.CSSProperties,
	}[][]
}

export type IGroupedResultTableProps = {
	columns: {
		name: string | false,
		valueType?: string,
		style?: React.CSSProperties,
	}[],
	rows: GroupedRow[],
}

export const GroupedResultTable = ({ dataTable }:{ dataTable: IGroupedResultTableProps}) : JSX.Element => {

	const renderRow = (groupedRow: GroupedRow): JSX.Element => {
		if (groupedRow.rows.length === 1) {
			return (<tr>
				<td>{groupedRow.scope}</td>
				{groupedRow.rows[0].map((v, i) => <td style={v.style} key={i}>{v.value}</td>)}
				<td style={{ fontWeight: 'bold' }}>{resultValue(groupedRow.total)}</td>
			</tr>);
		} else {
			return (<>
				{groupedRow.rows.map((r, index) => <tr key={index}>
					{index === 0 &&
                        <td rowSpan={groupedRow.rows.length}>{groupedRow.scope}</td>}
					{r.map((v, i) => <td style={v.style} key={i}>{v.value}</td>)}
					{index === 0 && <td rowSpan={groupedRow.rows.length} style={{ fontWeight: 'bold' }}>{resultValue(groupedRow.total)}</td>}
				</tr>)}
			</>);
		}
	};

	return (<>
		<table className={'grouped_result_table'}>
			<thead>
				<tr>
					{dataTable.columns.map((c, idx) => c.name &&
                        <th style={c.style} key={idx}>{c.name}</th>
					)}
				</tr>
			</thead>
			<tbody>
				{dataTable.rows.map((groupedRow) => renderRow(groupedRow))}
				<tr>
					<td style={{ fontWeight: 'bold' }}>Total</td>
					{dataTable.columns.slice(1, -1).map((_, idx) => <td key={idx}></td>)}
					<td style={{ fontWeight: 'bold' }}>{resultValue(dataTable.rows.reduce((acc, row) => acc + row.total, 0))}</td>
				</tr>
			</tbody>
		</table>
	</>);
};