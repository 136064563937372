import { gql } from '@apollo/client';

export const MUTATE_VALIDATION_LINE = gql`
    mutation ValidateEquipmentLine(
        $id: String!,
        $values: EquipmentValidation!
    ) {
        mutateValidateEquipmentLine(
            id: $id,
            values: $values
        ) {
            status,
            equipment {
                id,
                equipmentTypeName,
                equipmentTypeSpecification,
                equipmentTypeMetadata,
                name,
                unit,
                unitShortName,
                validated,
                specification,
                priority,
                quantity,
                unknown,
                quality,
                internalLifetime,
                reusePart,
                reuseLifetime,
                validationQuantity,
                quantityCheck,
                lifetimeCheck,
                validationSampleQuantityMean,
                lifetime,
                validationLifetime,
                validationSampleLifetimeMean,
                comment,
                consultComment,
                forbidden,
                category,
                defaultLifetime,
                isRelatedToResults
            }
        }
    }
`;
