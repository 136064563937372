import { IIndicator } from '../../domain/interfaces/IIndicator';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { IndicatorEntity } from '../../domain/entities/IndicatorEntity';
import { useDatasets } from './useDatasets';
import { useLocation, useParams } from 'react-router-dom';


export const useIndicators = (selected?: string[]): { entity: IndicatorEntity | undefined, indicators: IIndicator[], selectedIndicators: IIndicator[] } => {
	const location = useLocation();
	const inResultSampleView = location.pathname.includes('/results/sample');
	const inDashboardView = location.pathname.includes('/dashboard/overview');
	const { data: entity } = useSelector((state: State) => state.dataIndicatorsStatus);
	const selectedIndicatorForAll = useSelector((state: State) => state.selectedIndicatorsForAll);
	const { dataset: datasetId } = useParams<{ dataset: string}>();
	const indicators = entity?.get() ?? [];
	const { currentDataset } = useDatasets(datasetId ? [datasetId] : []);

	// Either use the selected indicators from the current dataset or the selected indicators from the props
	let selectedIndicators = indicators.filter((indicator: IIndicator) => currentDataset?.selectedIndicators?.includes(indicator.id));
	if (selected) {
		selectedIndicators = indicators.filter((indicator: IIndicator) => selected.includes(indicator.id));
	}
	// The datasetId 'all' it's not a "real" dataset, therefore, at the moment we shouldn't be able to "select" attached indicators
	// Same thing for benchmark results
	if (datasetId === 'all' || (!datasetId && inResultSampleView) || ((!datasetId && inDashboardView))) {
		if (selectedIndicatorForAll.length === 0) {
			selectedIndicators = indicators;
		} else {
			selectedIndicators = indicators.filter((indicator: IIndicator) => selectedIndicatorForAll.includes(indicator.id));
		}
	}
	return { entity, indicators, selectedIndicators };
};