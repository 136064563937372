export function getFiltersByDomain(domain: string): string[] {
	const domainFilters: { [key: string]: string[] } = {
		lcaresults: ['TotalFootprint', 'FootprintPerFunctionalUnit', 'FootprintDetailed', 'FootprintPerBlock', 'FootprintPerLCStep', 'FootprintEquivalence', 'ImpactScore'],
		normalizedresults: ['NormalizedFootprintDetailed', 'NormalizedFootprintPerBlock', 'NormalizedFootprintPerLCStep', 'NormalizedFootprintPerIndicator', 'EnvironmentalBudget', 'PlanetaryBoundaries'],
		corporateReporting: ['BGES'],
		quality: ['QualityScore', 'LowestQualityData', 'QualityOverview'],
		flux: ['ElectricityConsumption', 'FluxSummary'],
		maturity: ['Maturity', 'MaturityScore', 'MaturityComparisonByDomain'],
	};

	return domainFilters[domain] || [];
}