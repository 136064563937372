import { Study } from '../data/entries/Study';
import { Equipment } from '../data/entries/Equipment';
import { Setting } from '../data/entries/Setting';
import { Validation } from '../data/entries/Validation';
import { GoodPractice } from '../data/entries/GoodPractice';
import { Result } from '../data/entries/Result';
import { IResultLauncher } from '../data/entries/IResultLauncher';
import { Dataset } from '../data/entries/Dataset';
import { Core } from './Core';
import EventEmitter from 'events';
import { IAPIProps } from '../interfaces/IAPIProps';
import { INotification } from '../interfaces/INotification';

export type Entities = Study | Equipment | Setting | Validation | GoodPractice | Result | Dataset | IResultLauncher;
export type ObjectKey = string | number | symbol;
export type IfEquals<X, Y, A = X, B = never> =	(<T>() => T extends X ? 1 : 2) extends (<T>() => T extends Y ? 1 : 2) ? A : B;
export type WritableKeys<T> = { [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P> }[keyof T];
export type ReadonlyKeys<T> = { [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { readonly [Q in P]: T[P] }, P> }[keyof T]
export type PickWritableProperty<T> = Pick<T, WritableKeys<T>>
export type PickReadonlyProperty<T> = Pick<T, ReadonlyKeys<T>>
export type MutableProperties<T> = { [P in keyof PickWritableProperty<T>]: T[P] }
export type DataMutate<T> = {
	id: string,
	values: MutableProperties<T>
}
export type InputSetter = { get(): string, set(value: string): void, blur(skip?: boolean): void, line: ValueEntity | undefined }
export type InputProperty<T> = PickReadonlyProperty<T> & { [P in keyof PickWritableProperty<T>]: InputSetter }

/**
 * Inject Input Setter
 *
 * Give a object with getter and setter
 * and property to manage entity
 *
 * @param name
 * @param value
 * @param entity
 *
 * @author Maximilien Valenzano
 */
export function injectInputSetter<T>(name: keyof T, value: string, entity?: ValueEntity): InputSetter {
	const ref = { value };
	const obj = { value };
	return {
		get () { return obj.value; },
		set (value: string): void {
			obj.value = value;
			entity?.change(name, value !== ref.value);
		},
		blur (skip = false) {
			entity?.preSave(skip);
		},
		line: entity
	};
}

/**
 * Entity
 *
 * Used to store data and manage changes
 * for all data that user in all application
 *
 * @author Maximilien Valenzano
 */
export abstract class Entity {
	constructor(protected app: Core) {
		this.initialization();
	}

	initialization(): void {
		return;
	}

	public abstract set(obj: unknown | undefined): void

	abstract change(): Promise<void> | undefined

	abstract update(line?: unknown): void

	async getApiProps(): Promise<IAPIProps> {
		const token = await this.app.adapter.getToken?.() ?? '';
		const lang = this.app.adapter.getLang?.() ?? 'en';
		const adapter = this.app.adapter;
		return { token, lang, adapter };
	}

	errorMessage: string | undefined;

	error(error: Error): undefined {
		this.errorMessage = error.message;

		if (error.message === 'You need to be logged to perform this action') {
			this.app.adapter.storeError?.(error);
		}

		// Remove this condition to display backend error messages in frontend for all users
		if (this.app.entities.user?.data?.isSuperuser)
		{
			const notification: INotification = {
				message: error.message,
				type: 'error'
			};
			this.app.adapter.storeNotification?.(notification);
		}
		return undefined;
	}

	async callApi<T>(call?: (props: IAPIProps) => Promise<T>): Promise<T>;
	async callApi<T, Q>(call?: (props: IAPIProps, input?: Q) => Promise<T>, input?: Q): Promise<T>;
	async callApi<T, Q>(call?: (props: IAPIProps, input: Q) => Promise<T>, input?: Q): Promise<T>;
	async callApi<T, Q>(call?: (props: IAPIProps, input?: Q) => Promise<T>, input?: Q): Promise<T> {
		if (!call) throw new Error('No caller provided');

		const apiProps = await this.getApiProps();

		// Need to bind adapter to keep this adapter as this context
		return call.bind(this.app.adapter)(apiProps, input)
			.then(data => data)
			.catch(error => {
				this.error(error);
				const message = this.errorMessage && this.errorMessage.length > 0 ? this.errorMessage : 'Something went wrong';
				throw new Error(message);
			});
	}
}

/**
 * Dataset Entity
 *
 * Abstract class for all dataset entity
 *
 * @author Maximilien Valenzano
 */
export abstract class RelatedDatasetEntity {
	constructor(private app: Core, private parent: Entity) {
	}
	change(): void {
		this.parent.change();
	}
}

/**
 * Value Entity
 *
 * Abstract class for all value entity
 * This provides a way to manage value of data
 *
 * @author Maximilien Valenzano
 */
export abstract class ValueEntity {
	public event: EventEmitter = new EventEmitter();
	protected isChanged: ObjectKey[] = [];
	private changeTimeout: NodeJS.Timeout | undefined;

	protected constructor(protected app: Core, protected parent: RelatedDatasetEntity) {
	}

	hasChanges (): boolean { return !!this.isChanged.length; }

	/**
	 * This function is called by the set() function of the InputSetter object
	 * If a timeout is already running, it will clear it
	 *
	 * @param name - The name of the property that has changed
	 * @param change - Whether the property has changed or not
	 */
	change (name: ObjectKey, change: boolean): void {
		if (change && !this.isChanged.includes(name)) this.isChanged.push(name);
		if (!change && this.isChanged.includes(name)) {
			const idx = this.isChanged.indexOf(name);
			this.isChanged.splice(idx, 1);
		}
		this.parent.change();
		if (this.changeTimeout) clearTimeout(this.changeTimeout);
	}

	/**
	 * This function is called by the blur() function of the InputSetter object
	 *   - Clear changes and timeout if there are
	 *   - Then will start a timeout of 1 second before saving the data
	 *
	 * @param skip - Skip the timeout and save the data immediately
	 */
	preSave(skip = false) {
		if (!this.isChanged.length) return;
		if (!skip) {
			if (this.changeTimeout) clearTimeout(this.changeTimeout);
			this.changeTimeout = setTimeout(() => {
				this.save();
				this.isChanged = [];
			}, 1000);
		} else {
			this.save();
			this.isChanged = [];
		}
	}

	abstract save (): void
	abstract define (obj: unknown): unknown
}
