import { ValidationDatasetEntity, ValidationEquipmentEntity } from '../domain/entities/ValidationEntity';
import { EquipmentEntity, InventoryDatasetEntity } from '../domain/entities/InventoryEntity';
import { resultValue } from '../viewComponents/results/utils/resultValue';

export const blockHaveCmdb = (data: ValidationDatasetEntity, category: string) => {
	return data.data.cmdb.filter(cmdb => cmdb.equipmentsInfo.filter(e => e.category === category).length > 0).length > 0;
};

/**
 * Calculate average lifetime of equipments for a category, inside Validation or Inventory dataset entity
 * CMDB is taken into account
 *
 * @param data : ValidationDatasetEntity | InventoryDatasetEntity
 * @param list : list of equipments
 * @param category : category of equipments
 * @param stringReturn : true to get a string, false to get a number
 */
export const averageLifetime = (
	data: ValidationDatasetEntity | InventoryDatasetEntity,
	list: (ValidationEquipmentEntity | EquipmentEntity)[],
	category: string,
	stringReturn = true,
) => {
	let length = 0;
	const filtered = list.filter(e => e.data.category === category);
	const sumInList = filtered.reduce((acc, e) => {
		const newLength = e instanceof EquipmentEntity ? parseFloat(e.data.quantity.get()) : parseFloat(e.data.validationQuantity.get());
		const lifetime = e instanceof EquipmentEntity ? parseFloat(e.data.lifetime.get()) : parseFloat(e.data.validationLifetime.get());

		// Protect against NaN
		if (isNaN(newLength) || isNaN(lifetime)) return acc;
		length += newLength;
		return acc + (newLength * lifetime);
	}, 0);

	let sumInCmdb = 0;
	data.data.cmdb.forEach(cmdb => {
		const equipmentsInfo = cmdb.equipmentsInfo.filter(e => e.category === category)?.[0];
		if (equipmentsInfo) {
			length += equipmentsInfo.quantity;
			sumInCmdb += equipmentsInfo.totalLifetime;
		}
	});

	if (!stringReturn) return ((sumInList + sumInCmdb) / length);
	return (resultValue((sumInList + sumInCmdb) / length));
};

/**
 * Calculate the quantity of equipments for a category, inside Validation or Inventory dataset entity depending on the list and data
 * It takes into account the CMDB
 *
 * @param data - ValidationDatasetEntity | InventoryDatasetEntity
 * @param list - list of equipments
 * @param category - category of equipments
 * @param returnNumber - true to get a number, false to get a string
 *
 * @returns number | string
 */
export const sumEquipmentQuantityInCategory = (
	data: ValidationDatasetEntity | InventoryDatasetEntity,
	list: (ValidationEquipmentEntity | EquipmentEntity)[],
	category: string,
	returnNumber = false,
): number | string => {
	let itemInList = list.filter(e => e.data.category === category).reduce((acc, e) => {
		const quantity = e instanceof EquipmentEntity ? parseFloat(e.data.quantity.get()) : parseFloat(e.data.validationQuantity.get());

		// Protect against NaN
		if (isNaN(quantity)) return acc;
		return acc + quantity;
	}, 0);

	// For each cmdb in data.data.cmdb, get the quantity of the corresponding category
	data.data.cmdb.forEach(cmdb => {
		const equipmentsInfo = cmdb.equipmentsInfo.filter(e => e.category === category);
		if (equipmentsInfo.length > 0)
			itemInList += equipmentsInfo[0].quantity;
	});
	if (returnNumber) return itemInList;
	return resultValue(itemInList);
};

/**
 * return the sigma for the category selected in the sample
 * Can be used to get the sigma for the lifetime or for the quantity
 * @param category list of equipment
 * @param valueKey quantity or lifetime
 * @param checkKey quantityCheck or lifetimeCheck
 */
export const getSigmaForCategory = (
	category: ValidationEquipmentEntity[],
	valueKey: 'quantity' | 'lifetime',
	checkKey: 'quantityCheck' | 'lifetimeCheck'
): number | null => {
	if (category.length === 0) return null;
	const quantitySum = category.reduce((a, b) => a + b.data[valueKey], 0);
	const quantityTimesSigmaForSum = category.reduce(
		(a, b) => a + (b.data[valueKey] * b.data[checkKey]) ?? 0,
		0
	);

	if (quantitySum === 0 || quantityTimesSigmaForSum === 0) return 0;
	return quantityTimesSigmaForSum / quantitySum;
};


/**
 * Return the lifetime of the equipment, taking into account the internal lifetime, the percentage of the equipment that is reused and the lifetime of the reused part
 * @param {number} internalLifetime the internal lifetime of the equipment
 * @param {number} reusePart the percentage of the equipment that is reused
 * @param {number} reuseLifetime the lifetime of the reused part
 * @returns 
 */
export const calculateLifetime = (internalLifetime?: number, reusePart?: number, reuseLifetime?: number) => {
	const validadetValue = (value: number | undefined): number => {
		return value !== undefined ? value : 0;
	};
	const ilt = validadetValue(internalLifetime);
	const rp = validadetValue(reusePart);
	const rlt = validadetValue(reuseLifetime);
	const div = rp === 0 ? 0 : rp / 100;
	const add = div === 0 || rlt === 0 ? 0 : div * rlt;
	const lifetime = ilt + add;
	return lifetime;
};