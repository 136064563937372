import { gql } from '@apollo/client';

export const QUERY_DATA_INVENTORY = gql`
    query requestInventory (
        $datasetId: String!
    )
    {
        dataset (id: $datasetId) {
            id,
            name,
            shortName,
            equipments {
                id,
                name,
                help,
                unit,
                unitShortName,
                priority,
                specification,
                quantity,
                quality,
                unknown
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
                customElectricityConsumption,
                comment,
                assigned,
                assignedCount,
                forbidden,
                equipmentTypeId,
                flag,
                category,
                categoryIdentifier,
                cmdb,
                defaultLifetime,
                order,
                isRelatedToResults
            },
            cmdb {
            	id,
            	name,
            	category,
            		comment,
            	equipmentsInfo {
            		category,
                    categoryIdentifier,
            		quantity,
            		totalLifetime, 
            		numberOfType,
                    lifetime,
                    allocationFactor,
                    quality,
                    internalLifetime,
                    reusePart,
                    reuseLifetime,
                    defaultLifetime
				}
			},
            metadata {
                equipmentRate
            },
            priority
        },
    }
`;
