import { gql } from '@apollo/client';

export const MUTATE_LOCK_DATASET = gql`
    mutation LockDataset(
        $datasetId: String!,
        $lockCollect: Boolean,
        $lockValidation: Boolean
    ) {
        mutateLockDataset(
            datasetId: $datasetId,
            lockCollect: $lockCollect,
            lockValidation: $lockValidation
        ) {
            status,
            dataset {
                id,
                name,
                desc,
                archived,
                studyId
                status {
                    maturity,
                    inventory,
                    validated,
                    results,
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validate,
                    results,
                    validationOpen
                },
                hasDefinitiveResults
                hasSettings
            }
        }
    }
`;
