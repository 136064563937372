import React, { useState } from 'react';
import { IModal } from '../../domain/interfaces/IModal';
import { translate } from '../../infrastructure/translations/translate';
import Spinner from '../../viewComponents/utils/Spinner/Spinner';
import { createDashboardTableCreationModal } from './Table/DashboardTableCreationModal';
import { useUsersDashboard } from '../../viewComponents/hooks/useUsersDashboard';
import { useModal } from '../../viewComponents/modal/useModal';

export interface DashboardPinButtonProps {
	data: unknown;
	type: string;
	text?: boolean;
	prefill?: string;
}
export const DashboardPinButton = ({ data, type, text, prefill }: DashboardPinButtonProps) => {
	const [openPinModal, setOpenPinModal] = React.useState<boolean>(false);
	text = text ?? true;

	useModal(openPinModal, createDashboardPinModal(openPinModal, setOpenPinModal, type, data, prefill));

	return (<button type="button" className="button_blank dashboard_action" onClick={() => setOpenPinModal(true)}>
		<div className="dashboard_icon">
			<i className="fa-solid fa-thumb-tack" />
		</div>
		{text && <div className="dashboard_text">
			{translate('dashboard.action.pin')}
		</div>}
	</button>);
};

export const createDashboardPinModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	type: string,
	data: unknown,
	prefill?: string
): IModal | undefined => {
	const { entity: userDashboardEntity, usersDashboards } = useUsersDashboard();
	const [name, setName] = useState<string>(prefill ?? '');
	const [loading, setLoading] = useState<boolean>(false);
	const [step, setStep] = useState<number>(0);
	const [newTable, setNewTable] = useState<boolean>(false);

	const clear = (): void => {
		setLoading(false);
		setName('');
		setStep(0);
		setOpen(false);
		setNewTable(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
	};

	const handlePin = (dashboardId: string) => {
		setStep(2);
		userDashboardEntity?.mutateEditUserDashboardCard({
			id: 'new',
			name,
			dashboardId,
			type,
			data: JSON.stringify(data),
			delete: false
		}).then(() => {
			setStep(3);
			setTimeout(() => {
				clear();
			}, 2000);
		});
	};

	useModal(newTable, createDashboardTableCreationModal(newTable, setNewTable, (dashboardId) => {
		handlePin(dashboardId);
	}));

	if (!open || newTable) return;

	const body = (<div className="dashboard_pin_modal_list">
		{step == 0 && <div className="dashboard_pin_modal_name">
			<input
				type="text"
				placeholder={translate('dashboardPinModal.name') as string}
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
		</div>}
		{step == 1 && <>
			{usersDashboards.map((dashboard) => (
				<button
					type="button"
					className="button_blank dashboard_pin_modal_list_item_button"
					key={dashboard.id}
					onClick={() => handlePin(dashboard.id)}
				>
					<div className="dashboard_pin_modal_list_item">
						<div className="dashboard_pin_modal_list_item_name">{dashboard.name}</div>
						<div className="dashboard_pin_modal_list_item_desc">{dashboard.description}</div>
					</div>
				</button>)
			)}
			<button
				type="button"
				className="button_blank dashboard_pin_modal_list_item_button"
				onClick={() => setNewTable(true)}
			>
				<div className="dashboard_pin_modal_list_item">
					<div className="dashboard_pin_modal_list_item_name">{translate('dashboardPinModal.newTable')}</div>
				</div>
			</button>
		</>}
		{step == 2 && <div>
			<Spinner />
		</div>}
		{step == 3 && <div className="dashboard_pin_modal_success">
			<i className="fa-solid fa-check" />
			<div className="dashboard_pin_modal_success_text">{translate('dashboardPinModal.success')}</div>
		</div>}
	</div>);

	return  {
		action: () => setStep(step + 1),
		actionText: 'next',
		additionalFooterContent: undefined,
		body,
		header: translate('dashboardPinModal.header'),
		load: loading,
		footer: step > 0 ? <></> : undefined,
		onClose(): void {
			clear();
		},
		visible: true
	};
};