import { gql } from '@apollo/client';

export const MUTATE_MANAGE_RESULT_LAUNCHER = gql`
    mutation MutateManageResultLauncher(
        $id: String!,
        $name: String,
        $private: Boolean,
        $definitive: Boolean,
        $delete: Boolean
        $deleteAll: Boolean
    ){
        mutateManageResultLauncher(
        	id: $id,
			name: $name,
			private: $private,
			definitive: $definitive,
			delete: $delete,
			deleteAll: $deleteAll
        ){
            status,
            error,
            resultLauncher {
                id,
                name,
                computeLogs,
                timestamp,
                archived,
                private,
                definitive,
            }
            datasets {
            	id,
            	permissions {
                	collectOpen,
		            validationOpen
            	}
            	status {
					maturity,
					inventory,
					validated,
					results,
					impactsComplete
            	}
            	hasDefinitiveResults
            }
        }
    }
`;
